import React, { Component } from 'react';

class ProjectFeatures extends Component {
    render() {        
        let data = this.props.overviewData;

        return (
            <React.Fragment>
                <div className="grid-highlights">
                    <div className="left">
                        <img src={data.ovimg} alt="" />
                    </div>

                    <div className="right">
                        <h4> Project Overview </h4>
                        {data.project_overview.map((value) => (
                           <p> {value} </p>
                        ))}  
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectFeatures;