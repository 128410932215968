/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import TesimonialsText from 'components/common/testimonialsText';
import {Helmet} from "react-helmet-async";

class TestimonialDetail extends Component {

    state = {
        cname:"",
        ttype:"",
        csub:"",
        cimg:"",
        tdet:[],
        vlink:"",
        acc:0,
        loading: false,
    }


    async componentDidMount() {
        fetch("https://mithahomes.com/phpapi/siteapi/testimonial-details.php?no="+this.props.match.params.id)
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    cname: data.cname,
                    csub: data.csub,
                    cimg: data.cimg,
                    ttype: data.ttype,
                    tdet: data.tdet,
                    vlink: data.vlink,
                    acc: data.acc,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }

    async componentDidUpdate() {
        if(this.state.acc!==this.props.match.params.id){
            fetch("https://mithahomes.com/phpapi/siteapi/testimonial-details.php?no="+this.props.match.params.id)
            .then(response => {
                response.json().then(function(data) {
                if (data.success === 1) {
                    this.setState({
                        cname: data.cname,
                        csub: data.csub,
                        cimg: data.cimg,
                        ttype: data.ttype,
                        vlink: data.vlink,
                        tdet: data.tdet,
                        acc: data.acc,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                    });
                }
                }.bind(this));
            })
            .catch(error => {
                console.log(error);
            });    
        }
 }

    render() {     
        const testimonialId = this.props.match.params.id;
        console.log(testimonialId);

        return (
            <React.Fragment>
        <Helmet>
        <title>Mitha Homes</title>
    <meta name="description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>

                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> Testimonial Detail </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: TESTIMONIAL CONTENT */}
                <section className="testimonials testimonial-detail type-2">
                    <div className="container">
                        {this.state.ttype==="Video" ?
                    <div className="text-center">
                            <iframe src={this.state.vlink} className="iframe-youtube" frameborder="0"></iframe>
                        </div>:
                        <div className="title-1 text-center">
                             <p> {this.state.csub} </p>
                            <h2> {this.state.cname} </h2>
                            <img src={this.state.cimg} alt="" />
                            {/* <p className="date-location"> 
                                <span> <i className="fa fa-map-marker"></i> Chennai</span>
                                <i className="fa fa-calendar-o"></i> 23 Sep, 2022 
                            </p> */}

                        {this.state.tdet.map(item =>
                        <p> {item} </p>)}
                        </div>}
                    </div>
                </section>

                <section className="testimonials type-2">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> Other Testimonials </h2>
                        </div>
                        <div className="grid-testimonials">
                            <TesimonialsText testimonialId={this.props.match.params.id} />
                        </div>
                    </div>
                </section>
                {/* END :: TESTIMONIAL CONTENT */}
                
                <Footer />
            </React.Fragment>
        );
    }
}

export default TestimonialDetail;