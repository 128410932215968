import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EnquireNow from 'components/common/enquireModal';
//import {Fetchurl} from 'components/context';

import closeIcon from 'assets/images/whatsapp/whatspp-box-close-icon.png';
import avatarImage from 'assets/images/whatsapp/avatar-chat.png';

async function whatsappData(credentials) {
    return fetch('https://mithahomes.com/phpapi/siteapi/enquiry-api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
}

async function newsLetterData(credentials) {
    return fetch('https://mithahomes.com/phpapi/siteapi/newsletter-api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
}

const Footer = () => {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [emailId, setEmailId] = useState("");
    const [tagname, setTagname] = useState([]);
    const [taglink, setTaglink] = useState([]);
    const [newsLetter, setNewsLetter] = useState("");

    useEffect(()  => {
    async function fetchData() {
        fetch('https://mithahomes.com/phpapi/siteapi/footer-tag.php?no=1')
        .then(response => {
          response.json().then(function (data) {
            console.log(data.tagname);
            if (data.success === 1) {
                setTagname(data.tagname);
                setTaglink(data.taglink);
            }
        
        });
    }).catch(error => {
    console.log(error);
    });

    };
    fetchData();
    }, []);    

   
    const handleSubmit = async e => {
        e.preventDefault();
        const response = await whatsappData({
            name:name, mobile:mobile, email:emailId, city:"", pid: 0, ltype: "Whatsapp"
        });
          
        if(JSON.stringify(response.success) === '1') {
            console.log('Insert Success');
            alert("Your Details Updated. Contact Sortly");
            document.getElementById("toggleWhatsappCheckbox").checked = false;
            document.getElementsByClassName('whatsapp-inputs').value = '';
            var whatsappNewWindow = window.open('https://api.whatsapp.com/send/?phone=9443154732&text&type=phone_number&app_absent=0', '_blank');
            whatsappNewWindow.focus();        
        } else {
            console.log('Insert Failure');
        }
    }

    const handleSubmitNews = async e => {
        e.preventDefault();
        const response = await newsLetterData({
            name:newsLetter,
        });
          
        if(JSON.stringify(response.success) === '1') {
            console.log('Insert Success');
            alert("Your Details Updated. Contact Sortly");
          
        } else {
            console.log('Insert Failure');
        }
    }


    return (
        <React.Fragment>
            <footer>
                <div className="container">
                    {/* BEGIN :: FOOTER MENU WRAP */}
                    {/* <div className="footer-menu-wrap">
                        <h2 className="footer-title"> Our Project List </h2> */}
                         {/* BEGIN :: FOOTER MENU BOX */}
                         {tagname.length? <div className="footer-menu-wrap">
                        <h2 className="footer-title"> Our Project List </h2> <ul className="footer-menu-box">

                        {/* <li> <Link to={"/"+taglink[0]}> {taglink[0]} </Link> </li> */}

                         {tagname.map((value,index) => (
                         <li> <Link to={"/"+taglink[index]}> {value} </Link> </li>
                         ))}

                         </ul></div>:""}


                        {/* <ul className="footer-menu-box">
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            <li> <Link to="/"> Apartments in Thillai Nagar </Link> </li>
                            <li> <Link to="/"> Apartments in Cantonment </Link> </li>
                            
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            <li> <Link to="/"> Apartments in Thillai Nagar </Link> </li>
                            <li> <Link to="/"> Apartments in Cantonment </Link> </li>
                            
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            <li> <Link to="/"> Apartments in Thillai Nagar </Link> </li>
                            <li> <Link to="/"> Apartments in Cantonment </Link> </li>
                            
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            <li> <Link to="/"> Apartments in Thillai Nagar </Link> </li>
                            <li> <Link to="/"> Apartments in Cantonment </Link> </li>
                            
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            <li> <Link to="/"> Apartments in Thillai Nagar </Link> </li>
                            <li> <Link to="/"> Apartments in Cantonment </Link> </li>
                            
                            <li> <Link to="/"> 2 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> 3 BHK flats in Trichy </Link> </li>
                            <li> <Link to="/"> Apartments in Vayalur Road </Link> </li>
                            
                        </ul> */}
                        {/* END :: FOOTER MENU BOX */}
                    {/* </div> */}
                    {/* END :: FOOTER MENU WRAP */}
        
                    {/* BEGIN :: FOOTER ABOUT */}
                    <div className="footer-about">
                        <h4> About Mitha Construction and Development </h4>
                        <p>
                            Mitha Construction and Development is a registered construction firm in Tiruchirappalli, headed by S.Mohan, a qualified civil engineer from Seshasayee Institute of Technology (SIT). He has completed his course in 1985 and practised under experienced and leading engineers during the construction of big hotels and residential bungalows.
                        </p>
                    </div>
                    {/* END :: FOOTER ABOUT */}
                </div>
            </footer>

            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="footer-box-wrapper">
                            {/* BEGIN :: FOOTER BOX */}
                            <div className="footer-box">
                                <h2>newsletter subscribe</h2>
                                <h4>EMAIL ADDRESS</h4>
                                <form name="newlet" id="newlet" onSubmit={handleSubmitNews} action='' method="post">
                                    <div className="subscribe-form">
                                        <div className="wrap-forms">
                                            <input type="text" name="newemail" id="newemail" value={newsLetter} onChange={(e)=>setNewsLetter(e.target.value)} placeholder="Your email address" />
                                            <button type="submit" name="Newssub" value="Subscribe" className="btn-sign">Sign up</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* END :: FOOTER BOX */}

                            {/* BEGIN :: FOOTER BOX */}
                            <div className="footer-box">
                                <h2>Contact us</h2>
                                <h4 className="theme-clr2"> Mitha Construction and Development </h4>
                                <p>
                                    C-87, 1st floor, Sasthri Road, <br />
                                    Thilainagar, Trichy - 620 018.
                                </p>

                                <p> <i className="fa fa-mobile"></i> +91 99949 24502 | +91 94431 54732 </p>
                                <p> <i className="fa fa-phone"></i> +91 431 4210732 </p>
                                <p> <i className="fa fa-envelope-o"></i> mithahomessales@gmail.com </p>
                            </div>

                            {/* END :: FOOTER BOX */}

                            {/* BEGIN :: FOOTER BOX */}
                            <div className="footer-box">
                                <h2>Useful Links</h2>
                                <ul>
                                    <li><Link to="/about"> <i className="fa fa-angle-right"></i> About Us</Link></li>
                                    <li><Link to="/vision-mission"> <i className="fa fa-angle-right"></i> Vision Mission </Link></li>
                                    <li><Link to="/contact"> <i className="fa fa-angle-right"></i> Contact </Link></li>
                                    {/* <li><a href="https://mithahomes.com/demo/privacyPolicy" target="_blank" rel="noreferrer"> <i className="fa fa-angle-right"></i> Privacy Policy </a></li>
                                    <li><Link to="/"> <i className="fa fa-angle-right"></i> Terms of use </Link></li> */}
                                </ul>
                            </div>
                            {/* END :: FOOTER BOX */}

                            {/* BEGIN :: FOOTER BOX */}
                            <div className="footer-box">
                                <h2>Social Media Links</h2>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/mithahomestrichy" target="_blank" rel="noreferrer"> 
                                            <i className="fa fa-facebook"></i> Facebook 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/mithahomestrichy/" target="_blank" rel="noreferrer"> 
                                            <i className="fa fa-instagram"></i> Instagram 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCks4MwD1-t2eQurR7I2JqUQ" target="_blank" rel="noreferrer"> 
                                            <i className="fa fa-youtube-play"></i> Youtube 
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* END :: FOOTER BOX */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer_middle_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-xs-12">
                            <div className="footer_menu_widget">
                                <ul>
                                    <li className="list-inline-item"><Link to="/"> Home </Link></li>
                                    <li className="list-inline-item"><Link to="/about"> About us </Link></li>
                                    <li className="list-inline-item"><Link to="/news-media"> News & Media </Link></li>
                                    <li className="list-inline-item"><Link to="/projects-list"> Our Projects </Link></li>
                                    <li className="list-inline-item"><Link to="/contact"> Contact Us </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-xs-12">
                            <div className="copyright-widget">
                                <p>All Rights Reserved. © Copyright 2022 mithahomes.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="fixed-bottom-strap">
                <div className="container">
                    <div className="grid-bottom-strap">
                        <div className="fx-box" >
                        <Link to="/contact" className="hidden-xs"> <i className="fa fa-envelope-o"></i> <span className="hidden-xs"> Enquire now </span> </Link>
                        </div>
                        <div className="fx-box">
                            <Link to="tel:+91 9791381108" className="visible-xs"> <i className="fa fa-phone"></i> <span className="hidden-xs"> Call us  </span></Link>
                            <Link to="/contact" className="hidden-xs"> <i className="fa fa-phone"></i> <span className="hidden-xs"> Contact Us </span> </Link>
                        </div>
                        <label htmlFor="toggleWhatsappCheckbox" className="fx-box">
                            <p> <i className="fa fa-whatsapp"></i> <span className="hidden-xs"> Chat in Whatsapp </span> </p>
                        </label>
                    </div>
                </div>
            </div>

            <input type="checkbox" id="toggleWhatsappCheckbox" className="dis0" />
            <div className="whatsapp-toggle-box">
                <div className="whatsapp-toggle-box-header">
                    <label htmlFor="toggleWhatsappCheckbox">
                        <img
                        src={closeIcon}
                        className="close-whatsapp"
                        alt=""
                        />
                    </label>
                    <div> </div>
                    <h4> Hi, Welcome </h4>
                </div>

                <div className="whatspp-toggle-box-body">
                    <div className="avatar">
                        <div><img src={avatarImage} alt="" /></div>
                        <div>
                            <p>We are always here to help you.</p>
                            <h4> Customer Care </h4>
                            <span className="online"> Online </span>
                        </div>
                    </div>
            
                    <form onSubmit={handleSubmit} id="whatsappForm" className="form-area">
                        <div>
                            {/* BEGIN :: USER INPUT WRAP */}
                            <div className="user-input-wrp">
                                <input type="text" className="inputText whatsapp-inputs" required="" value={name} onChange={(e) => setName(e.target.value)} name="name" />
                                <span className="floating-label"> Name* </span>
                                <i className="fa fa-user"></i>
                            </div>
                            {/* END :: USER INPUT WRAP */}
                        </div>
                
                        <div>
                            {/* BEGIN :: USER INPUT WRAP */}
                            <div className="user-input-wrp">
                                <input type="text" className="inputText whatsapp-inputs" required="" value={mobile} onChange={(e) => setMobile(e.target.value)} name="mobile" />
                                <span className="floating-label"> Mobile* </span>
                                <i className="fa fa-mobile"></i>
                            </div>
                            {/* END :: USER INPUT WRAP */}
                        </div>
                
                        <div>
                            {/* BEGIN :: USER INPUT WRAP */}
                            <div className="user-input-wrp">
                                <input type="text" className="inputText whatsapp-inputs" required="" value={emailId} onChange={(e) => setEmailId(e.target.value)} name="emailId" />
                                <span className="floating-label"> Email ID* </span>
                                <i className="fa fa-envelope-o"></i>
                            </div>
                            {/* END :: USER INPUT WRAP */}
                        </div>
                
                        <div>
                            <button className="btn-send">Submit</button>
                        </div>
                    </form>
                </div>
            </div>

            <EnquireNow />
        </React.Fragment>
    );
}
export default Footer;