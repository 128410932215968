import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import mithaConstruction from 'assets/images/Mitha-Homes-Logo.png';
import ResponsiveMenu from '../responsiveMenu';

class Header extends Component {
    state = {
        cData: [],
    };
    
    componentDidMount() {
        this.fetchDatas();
    }
  
    fetchDatas = () => {
        fetch('https://mithahomes.com/phpapi/siteapi/menu.php')
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    cData: data.data,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }  

    showSubMenu(menuItems) {
        return (
            menuItems.map(item =>
                <div key={item.cid} className="accordion-item">
                    <h2 className="accordion-header" id="panelCommerical-headingOne">
                        <button className="accordion-button dropdown-item collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelCommerical-${item.cid}`} aria-expanded="false" aria-controls="panelCommerical-collapseOne">
                            {item.category_name}
                        </button>
                    </h2>
                    <div id={`panelCommerical-${item.cid}`}  className="accordion-collapse collapse" aria-labelledby="panelCommerical-headingOne">
                        <div className="accordion-body">
                                { 
                                    item.project_data.map(innerMenu =>
                                        <NavLink className="dropdown-item" to={"/projects/"+innerMenu.pid+'#projectHighlights'}> {innerMenu.project_name} </NavLink>
                                    )
                                }
                        </div>
                    </div>
                </div>
            )
        );
    }

    render() {
        const menuItems = this.state.cData;

        return (
            <React.Fragment>
                <header className="hidden-sm-xs">
                    <div className="container">
                        <div className="grid-header">
                            <div className="header-logo">
                                <Link to="/">
                                    <img src={mithaConstruction} alt="" />
                                </Link>
                            </div>

                            <div className="header-menu navbar-expand-lg">
                                {/* BEGIN :: MENU */}
                                <div className="collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav mb-lg-0">
                                        {/* <li className="nav-item">
                                            <button type="button" className="btn-2" data-bs-toggle="modal" data-bs-target="#enquireNowModal"> Book now </button>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/contact"> Contact Us </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/news-media"> News & Media </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/testimonial"> Testimonials </NavLink>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <NavLink className="nav-link dropdown-toggle" to="/" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Projects
                                            </NavLink>
                                            <ul className="dropdown-menu lg" aria-labelledby="navbarDropdown2">
                                                <li>
                                                    <div className="accordion" id="accordionPanelCommercial">
                                                        {/* BEGIN :: DYNAMIC MENU */}
                                                        { this.showSubMenu(menuItems) }
                                                        {/* END :: DYNAMIC MENU */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <NavLink className="nav-link dropdown-toggle" to="/" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                About Us
                                            </NavLink>
                                            <ul className="dropdown-menu lg" aria-labelledby="navbarDropdown2">
                                                <li>
                                                <NavLink className="dropdown-item" to="/about"> History </NavLink>
                                                <NavLink className="dropdown-item" to="/vision-mission"> Vision Mission </NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                {/* END :: MENU */}
                            </div>
                        </div>
                    </div>
                </header>

                <header className="visible-sm-xs">
                    <div className="grid-header-sm-xs">
                        <div className="left">
                            <Link to="/">
                                <img src={mithaConstruction} alt="" />
                            </Link>
                        </div>

                        <div className="right">
                            <Link to="tel:+91 908 987 9898"> <i className="fa fa-phone"></i> Call </Link>
                            <button data-bs-toggle="offcanvas" data-bs-target="#slideMenu1"> <i className="fa fa-bars"></i> </button>
                        </div>
                    </div>
                </header>
                <ResponsiveMenu />
            </React.Fragment>
        );
    }
}
export default Header;