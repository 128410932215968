import React, { Component } from 'react';
import {Link} from 'react-router-dom';
require('./startup-modal.css');

class StartupModal extends Component {    
    render() {     
        return (
            <React.Fragment>
                {/* BEGIN :: STARTUP MODAL */}
                <div className="modal fade modal-2" id="startupModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* BEGIN :: BODY */}
                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <Link to="/project-detail/Mitha-Haritham" data-bs-dismiss="modal">
                                    <img src="https://mithahomes.com/demo/images/mitha-haritham-residential-apartment.jpg" alt="Mitha Haritham Residential Apartment" />
                                </Link>
                            </div>
                            {/* END :: BODY */}
                        </div>
                    </div>
                </div>
                {/* END :: STARTUP MODAL */}

                <button data-bs-toggle="modal" id="triggerStartup" data-bs-target="#startupModal" className="dis0"></button>
            </React.Fragment>
        );
    }
}

export default StartupModal;