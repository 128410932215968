import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { getNews } from "../../_services/fakeNews";
class News extends Component {
    state = {
        news : []
    }

    async componentDidMount() {
        this.fetchDatas();  
        // const news = getNews();
        // this.setState({ news });
    }

    fetchDatas = () => { 
        
 fetch('https://mithahomes.com/phpapi/siteapi/bloglist.php')
            .then(response => {
              response.json().then(function (data) {
                if (data.success === 1) {
                  this.setState({
                    news: data.data,
                });
                  
           // console.log(data.data);
  }
  else {
    this.setState({
      loading: false,
    });
    // this.context.post_show(false);
  }
}.bind(this));
})
.catch(error => {
console.log(error);
});

}

    render() {
        const { news } = this.state;

        return (
            <React.Fragment>
            {/* BEGIN :: NEWS BOX OR TESTIMONIAL BOX */}
            {
                news.map((news) => (
                    <Link key={news.acc} to={"/news-media-detail/"+news.acc} title={news.blog_name} className="news-box testimonial-box">
                        <div className="img-wrap">
                            <img src={news.simg} alt={news.blog_name} />
                        </div>
                        <div className="content">
                            <h3> {news.blog_name} </h3>
                            <p> {news.post_date} </p>
                        </div>
                    </Link>
                ))
            }
            {/* END :: NEWS BOX OR TESTIMONIAL BOX */}
            </React.Fragment>
        );
    }
}
export default News;