import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { getTestimonialsText } from "../../_services/fakeTestimonials";

class TestimonialsText extends Component {
    state = {
        testimonials : []
    }

    async componentDidMount() {
        fetch("https://mithahomes.com/phpapi/siteapi/testimonials.php")
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    testimonials: data.data,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }

    fetchTestimonials(testimonial, testimonialId) {
        if(testimonial.acc !== testimonialId) {
            return(
                <Link key={testimonial.acc} to={"/testimonial-detail/"+testimonial.acc} title={testimonial.cname} className="testimonial-box press">
                    <div className="content text-left">
                        <div className="combined-img-info">
                            <div className="left">
                                <img src={testimonial.cimg} alt={testimonial.cname} title={testimonial.cname} />
                            </div>

                            <div className="right">
                                <p> {testimonial.csub} </p>
                                <h3> {testimonial.cname}​ </h3>
                            </div>
                        </div>
                        <div className='testimonial-content'>
                        {testimonial.tdet.map(item =>
                        <p> {item} </p>)}
                        </div>

                        {/* <p> {testimonialId} </p> */}
                        <span className="read-more" to="/"> Read more </span>
                    </div>
                </Link>
            );
        }
    }

    render() {
        const testimonials = this.state.testimonials;
        const testimonialId = this.props.testimonialId;

        return (
            <React.Fragment>
            {/* BEGIN :: TESTIMONIAL BOX */}
            {
                testimonials.map((testimonial) => (
                    this.fetchTestimonials(testimonial, testimonialId)
                ))
            }
            {/* END :: TESTIMONIAL BOX */}
            </React.Fragment>
        );
    }
}
export default TestimonialsText;