import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

class Specification extends Component {
    render() {        
        let data = this.props.specificationData;

        return (
            <React.Fragment>
                <div className="grid-highlights">
                    <div className="left">
                        <img src={data.ovimg} alt="" />
                    </div>

                    <div className="right">
                        <h4> Specification </h4>
                     
                        <ul className="style-1">
                            {data.specification.map((value) => (
                                <li> {value} </li>
                            ))} 
                        </ul>
                        
                        <div className="view-all-block">
                            <a href="#propertySpecifiations" className="btns btn-1"> 
                                View All Specification &nbsp;
                                <i className="fa fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Specification;