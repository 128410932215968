import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

class Amenities extends Component {
    render() {        
        let data = this.props.amenitiesData;

        return (
            <React.Fragment>
                <div className="grid-highlights">
                    <div className="left">
                        <img src={data.ovimg} alt="" />
                    </div>

                    <div className="right">
                        <h4> Amenities </h4>
                        <ul className="style-1">
                            {data.amenities.map((value) => (
                                <li> {value} </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Amenities;