import React, { Suspense, Component} from 'react';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/common/scrollToTop';
import Home from './components/home';
import About from './components/about';
import VisionMission from './components/visionMission';
import Projects from './components/projects';
import ProjectsList from './components/projects/projectindex';

import ProjectDetail from './components/projectDetail';
import Testimonial from './components/testimonials';
import TestimonialVideo from './components/testimonials/tesimonialVideo';
import NewsMedia from './components/newsMedia';
import NewsMediaDetail from './components/newsMedia/newsDetail';
import Contact from './components/contact';
import TestimonialDetail from './components/testimonials/testimonialDetail';

class AppRoutes extends Component {    
    render() {
        return (
            <React.Fragment>
                <ScrollToTop />
                <Suspense>
                    <Switch basename="/">  
                        <Route exact path="/" component={Home} />  
                        <Route path="/about" component={About} />     
                        <Route path="/vision-mission" component={VisionMission} />  
                        <Route path="/projects/:id" component={Projects} />  
                        <Route path="/projects-list" component={ProjectsList} /> 
                        <Route path="/project-detail/:id" component={ProjectDetail} />
                        <Route path="/testimonial" component={Testimonial} />
                        <Route path="/testimonial-detail/:id" component={TestimonialDetail} />
                        <Route path="/testimonial-video" component={TestimonialVideo} />
                        <Route path="/news-media" component={NewsMedia} />
                        <Route path="/news-media-detail/:id" component={NewsMediaDetail} />
                        <Route path="/contact" component={Contact} />
                    </Switch>
                </Suspense>
            </React.Fragment>
        );
    }
}
export default AppRoutes;