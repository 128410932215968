import React, { Component } from 'react';
import loadingImage from 'assets/images/loading.gif';

class CommercialPlots extends Component {
    async componentDidMount() {
        window.location.assign('/project-detail/'+this.props.match.params.id);
    }

    render() {        
        return (
            <React.Fragment>
               <section className="pageloader-fixed">
                    <img src={loadingImage} alt="" />
               </section>
            </React.Fragment>
        );
    }
}

export default CommercialPlots;