/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet-async";

class Contact extends Component {
    render() {        
        return (
            <React.Fragment>
                <Helmet>
        <title>Mitha Homes</title>
    <meta name="description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> Contact Us </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: CONTACT CONTENT */}
                <section className="contact">
                    <div className="container-fluid p0">
                        <div className="grid-contact">
                            <div className="grid-contact-right corporate-office">
                                <div className='contact-holder'>
                                    <ul className="contact lite">
                                        <li> 
                                            <h4> Mitha Construction and Development </h4> 
                                            C-87, 1st floor, Sasthri Road, <br />
                                            Thilainagar, Trichy - 620 018.
                                        </li>
                                        <li> +91 99949 24502 | +91 94431 54732 | +91 431 4210732  </li>
                                        <li> Email : mithahomessales@gmail.com </li>
                                        <li>
                                            <a href="https://maps.app.goo.gl/zZnziLSAPA32gvRH8" target="_blank" className="btns btn-3" rel="noreferrer"> 
                                                Get Direction <img src='https://mithahomes.com/demo/images/arrow-right-orange.png' alt="" /> 
                                            </a>
                                        </li>
                                    </ul>
                                    
                                    <iframe src="https://mithahomes.com/cframe/index.html" className='iframe-form' title="Iframe Example"></iframe>
                                </div>
                            </div>

                            <div className="contact-map">
                                <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125406.03748936123!2d78.61898705854398!3d10.816005378392466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf50ff2aecdad%3A0x6de02c3bedbbaea6!2sTiruchirappalli%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1658761843866!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END :: CONTACT CONTENT */}
                
                <Footer />
            </React.Fragment>
        );
    }
}

export default Contact;