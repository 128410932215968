import React, { Component } from 'react';

class Banner extends Component {
    render() {        
        return (
            <React.Fragment>
                <section className="slider-block">
                    <div className="container-fluid p0">
                        <img src="https://mithahomes.com/demo/images/mitha-homes-trichy-cover.jpg" className="w-100" title='Mitha Homes' alt="mitha-homes" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Banner;