import React, { Component } from 'react';
import {Helmet} from "react-helmet-async";
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import StartupModal from 'components/home/startupModal';
import Projects from 'components/common/projects';
import TesimonialsText from 'components/common/testimonialsText';
import About from 'components/home/about';
import Banner from './banner';

// import { Link } from 'react-router-dom';

class Home extends Component {
    componentDidMount() {
        document.getElementById("triggerStartup").click();
    }
    
    render() {    
        return (
            <React.Fragment>
                 <Helmet>
        <title>Mitha Construction & Development Trichy</title>
    <meta name="description" content="Mitha Construction and Development is a registered construction firm in Tiruchirappalli. The company has a strong team of dedicated engineers and architects to execute projects as per the specifications and requirements of the clients." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Construction and Development is a registered construction firm in Tiruchirappalli. The company has a strong team of dedicated engineers and architects to execute projects as per the specifications and requirements of the clients." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>
                <Header />
                <Banner />
                
                {/* BEGIN :: PROJECTS */}
                <section className="projects">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> OUR PROJECTS </h2>
                        </div>
                    </div>
            
                    <div className="container">
                        <div className="grid-project-box">
                            <Projects />
                        </div>
                    </div>
                </section>
                {/* END :: PROJECTS */}

                {/* BEGIN :: TESTIMONIALS */}
                <section className="testimonials">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> TESTIMONIALS </h2>
                            <p> What Our Loving Clients Saying </p>
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="grid-testimonials">
                            <TesimonialsText />
                        </div>
                    </div>
                </section>
                {/* END :: TESTIMONIALS */}

                <About />
                <Footer />
                <StartupModal />
            </React.Fragment>
        );
    }
}

export default Home;