import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/common/header';
import Tesimonials from 'components/common/testimonials';
import Footer from 'components/common/footer';

class TestimonialVideo extends Component {
    render() {        
        return (
            <React.Fragment>
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> Testimonials </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: TESTIMONIAL CONTENT */}
                <section className="testimonials type-2">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> Testimonials </h2>
                            <p> What Our Loving Clients Saying </p>
                        </div>
                    </div>

                    <div className="container">
                        <div className="testimonial-options">
                            <Link to="/testimonial"> Text </Link>
                            <Link to="/testimonial-video" className="active"> Video </Link>
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="grid-testimonials">
                            <Tesimonials />
                        </div>
                    </div>
                </section>
                {/* END :: TESTIMONIAL CONTENT */}
                
                <Footer />
            </React.Fragment>
        );
    }
}

export default TestimonialVideo;