import React, { Component } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';

class Flat2 extends Component {
    render() {        
        let source = this.props.source;   
        
        return (
            <React.Fragment>
                <div className="grid-floor-plans centered">
                    {/* BEGIN :: FLOOR PLAN BOX */}
                    <div className="floor-plan-box">
                        <LightGallery
                            speed={500}
                            plugins={[lgZoom]}
                        >
                           {source.map((value,index) => (
                            index===1?<a href={value}> <img src={value} alt="" /> </a>:<a href={value}> </a>
                           
                            ))}  
                        </LightGallery>
                    </div>
                    {/* END :: FLOOR PLAN BOX */}
                </div>
            </React.Fragment>
        );
    }
}

export default Flat2;