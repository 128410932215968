import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import mithaConstruction from 'assets/images/mitha-construction.png';

class ResponsiveMenu extends Component {   
    state = {
        cData: [],
    };
    
    componentDidMount() {
        this.fetchDatas();
    }
  
    fetchDatas = () => {
        fetch('https://mithahomes.com/phpapi/siteapi/menu.php')
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    cData: data.data,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }  

    showSubMenu(menuItems) {
        return (
            menuItems.map(item =>
                        <div key={item.cid} className="accordion-item">
                                        <h2 className="accordion-header" id={`#panelCommericalXs-${item.cid}`}>
                                            <button className="accordion-button collapsed dropdown-item" type="button" data-bs-toggle="collapse" data-bs-target={`#panelCommericalXs-${item.cid}`} aria-expanded="false" aria-controls={`#panelCommericalXs-${item.cid}`}>
                                            {item.category_name}
                                            </button>
                                        </h2>
                                        <div id={`panelCommericalXs-${item.cid}`} className="accordion-collapse collapse" aria-labelledby="panelProjects-headingOne">
                                            <div className="accordion-body">
                                            { 
                                    item.project_data.map(innerMenu =>
                                        <Link data-bs-dismiss="offcanvas" className="dropdown-item" to={"/projects/"+innerMenu.pid+'#projectHighlights'}>  {innerMenu.project_name} </Link>
                                    )
                                    }
                                                
                                            </div>
                                        </div>
                                    </div>


            )
        );
    } 
   render() {
    const menuItems = this.state.cData;
        return(
            <div className="offcanvas offcanvas-start offcanvas-1" tabIndex="-1" id="slideMenu1" aria-labelledby="slideMenu1ExampleLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <div className="user-profile">
                        <div className="profile-img">
                            <Link data-bs-dismiss="offcanvas" to="/"> 
                                <img src={mithaConstruction} alt="" /> 
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-body">
                    <div className="canvas-holder-1">
                        <ul className="style-1">
                            <li> <Link data-bs-dismiss="offcanvas" to="/"> Home </Link> </li>
                            <li> <Link data-bs-dismiss="offcanvas" to="/about"> About Us </Link> </li>
                            <li>
                                <input type="checkbox" className="accordion-xs-radio" id="xsSubMenu1" />
                                <label htmlFor="xsSubMenu1"> Projects </label>

                                <div className="accordion accordion-xs" id="accordionPanelStayOpenExampleXs">
                                    {/* BEGIN :: DYNAMIC MENU */}
                                    { this.showSubMenu(menuItems) }
                                    {/* END :: DYNAMIC MENU */} 
                                </div>
                            </li>
                            <li> <Link data-bs-dismiss="offcanvas" to="/testimonial"> Testimonials </Link> </li>
                            <li> <Link data-bs-dismiss="offcanvas" to="/news-media"> News & Media </Link> </li>
                            <li> <Link data-bs-dismiss="offcanvas" to="/contact"> Contact Us </Link> </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
   }
}

export default ResponsiveMenu;