/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { getProjects } from "../../_services/fakeProjects";
import { getTestimonials } from "../../_services/fakeTestimonials";
import mapMarkerImg from "assets/images/icons/map-marker.png";
import bedImg from "assets/images/icons/bed.png";
import statusIcon from "assets/images/icons/project-status-icon.png";

class Projects extends Component {
    state = {
        projects : [],
        testimonials : []
    }

    async componentDidMount() {
      //  const projects = getProjects();
        const testimonials = getTestimonials();
      //  this.setState({ projects });
        this.setState({ testimonials });
        this.fetchDatas();
    }

   
    fetchDatas = () => {
        fetch('https://mithahomes.com/phpapi/siteapi/projectlist.php')
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    projects: data.data,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }  

    fetchProjects(project, projectId) {
        if(project.acc !== projectId) {
            return (
                <div key={project.acc} className="project-box">
                    <div className="top">
                        <h4> <Link to={"/projects/"+project.acc}> {project.project_name} </Link> </h4>
                        <p> {project.project_location} </p>
                    </div>

                    <div className="inner">
                        <div className="img-wrap">
                            <Link to={"/projects/"+project.acc} title={project.project_name}> <img src={project.img} alt={project.project_name} /> </Link>

                            <div className="share-wrap">
                                <label htmlFor={`share-${project.acc}`} className="share-btn">
                                    <i className="fa fa-share-alt"></i>
                                </label>
                                <input type="checkbox" className="checkbox-share" id={`share-${project.acc}`} />

                                <div className="share-icons-box">

                                <a className="share-icon fb" type="button" role="button" title="Share on facebook"
   href={"https://www.facebook.com/sharer/sharer.php?u=https://mithahomes.com/react/project-detail/"+project.acc} target="_blank" rel="noopener">
  <i className="fa fa-facebook"></i> </a>

  <a className="share-icon tw" type="button" role="button" title="Share on Instagram"
   href={"https://www.instagram.com/?url=https://mithahomes.com/react/project-detail/"+project.acc} target="_blank" rel="noopener">
  <i className="fa fa-instagram"></i> </a>

  <a className="share-icon wh" type="button" role="button" title="Share on Instagram"
   href={"whatsapp://send?text=https://mithahomes.com/react/project-detail/"+project.acc} target="_blank" rel="noopener">
  <i className="fa fa-whatsapp"></i> </a>

  
                                    {/* <Link to="/" className="share-icon fb"> <i className="fa fa-facebook"></i> </Link> */}
                                    {/* <Link to="/" className="share-icon tw"> <i className="fa fa-instagram"></i> </Link> */}
                                    {/* <Link to="/" className="share-icon wh"> <i className="fa fa-whatsapp"></i> </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <h2> {project.project_name} </h2>
                            
                            <ul className="features">
                                <li>
                                    <span className="icon"> <img src={mapMarkerImg} alt="" /> </span>
                                    {project.project_location}
                                </li>
                                <li>
                                    <span className="icon"> <img src={bedImg} alt="" /> </span>
                                    {project.project_type}
                                </li>
                                <li>
                                    <span className="icon"> <img src={statusIcon} alt="" /> </span>
                                    {project.category_name}
                                </li>
                            </ul>
                            <div className="btn-groups">
                                <Link to={"/projects/"+project.acc} className="btns btn-1"> <i className="fa fa-hand-pointer-o"></i> View More </Link>
                                <a href={project.brochare} target="_blank" rel="noreferrer" className="btns btn-2" ><i className="fa fa-file-pdf-o"></i> Brochure</a>
                                {/* <Link to={"/project-detail/"+project.acc} className="btns btn-2"> <i className="fa fa-file-pdf-o"></i> Brochure </Link> */}
                                <a href={project.get_direction} target="_blank" className="btns" rel="noreferrer"> <i className="fa fa-long-arrow-right"></i> Get Direction</a>
                                {/* <Link to="/" className="btns"> <i className="fa fa-user-o"></i> Refer to friend </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const projects = this.state.projects; 
        const projectId = this.props.projectId;

        return (
            <React.Fragment>
                {/* BEGIN :: PROJECT BOX */}
                {projects.map((project) => (
                    this.fetchProjects(project, projectId)
                ))}
                {/* END :: PROJECT BOX */}
            </React.Fragment>
        );  
    }
}
export default Projects;