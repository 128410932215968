import React, { Component } from 'react';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import { Link } from 'react-router-dom';

import visionImg from 'assets/images/vision-mission/mithahomes-vision.png';
import missionImg from 'assets/images/vision-mission/mithahomes-mission.png';
import coreValueImg from 'assets/images/vision-mission/mithahomes-corevalue.png';
import goalImg from 'assets/images/vision-mission/mithahomes-goal.png';
import {Helmet} from "react-helmet-async";

class VisionMission extends Component {
    render() {        
        return (
            <React.Fragment>
                <Helmet>
        <title>Mitha Homes</title>
    <meta name="description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> Vision Mission </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: VISION MISSION CONTENT */}
                <section className="about-content vision-mission">
                    <div className="container">
                        {/* BEGIN :: GRID ABOUT COMMON */}
                        <div className="grid-about-common small">
                            <div className="inner">
                                <div className="left">
                                    <img src={visionImg} alt="" className="w-100" />
                                </div>

                                <div className="right content">
                                    <div className="holder">
                                        <h4> Vision </h4>
                                        <p>
                                            To provide quality services that exceeds the expectations of our esteemed customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END :: GRID ABOUT COMMON */}
                        
                        {/* BEGIN :: GRID ABOUT COMMON */}
                        <div className="grid-about-common">
                            <div className="inner">
                                <div className="right content hidden-sm-xs">
                                    <div className="holder r-arrow">
                                        <h4> Mission </h4>
                                        <p>
                                            To build longtime relationships with our clients and to provide exceptional customer services by pursuing business through innovative and advanced technology.
                                        </p>
                                    </div>
                                </div>
                                <div className="left">
                                    <img src={missionImg} alt="" className="w-100" />
                                </div>
                                <div className="right content visible-sm-xs">
                                    <div className="holder r-arrow">
                                        <h4> Mission </h4>
                                        <p>
                                            To build longtime relationships with our clients and to provide exceptional customer services by pursuing business through innovative and advanced technology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END :: GRID ABOUT COMMON */}
                        
                        {/* BEGIN :: GRID ABOUT COMMON */}
                        <div className="grid-about-common small">
                            <div className="inner">
                                <div className="left">
                                    <img src={coreValueImg} alt="" className="w-100" />
                                </div>

                                <div className="right content">
                                    <div className="holder">
                                        <h4> Core Values </h4>
                                        <ul className="style-1">
                                            <li> We believe in treating our customers with respect and faith. </li>
                                            <li> We grow through creativity, invention and innovation. </li>
                                            <li> We integrate honesty, integrity and business ethics into all aspects of our business functioning. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END :: GRID ABOUT COMMON */}
                        
                        {/* BEGIN :: GRID ABOUT COMMON */}
                        <div className="grid-about-common">
                            <div className="inner">
                                <div className="right content hidden-sm-xs">
                                    <div className="holder r-arrow">
                                        <h4> Goals </h4>
                                        <ul className="style-1">
                                            <li> To build good reputation in the field of construction and promotion and become a key player in the industry. </li>
                                            <li> Regional expansion in the field of property development and maintain a strong base of key customers. </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="left">
                                    <img src={goalImg} alt="" className="w-100" />
                                </div>
                                <div className="right content visible-sm-xs">
                                    <div className="holder r-arrow">
                                        <h4> Goals </h4>
                                        <ul className="style-1">
                                            <li> To build good reputation in the field of construction and promotion and become a key player in the industry. </li>
                                            <li> Regional expansion in the field of property development and maintain a strong base of key customers. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END :: GRID ABOUT COMMON */}
                    </div>
                </section>
                {/* END :: VISION MISSION CONTENT */}

                <Footer />
            </React.Fragment>
        );
    }
}

export default VisionMission;