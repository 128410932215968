/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import { Link } from 'react-router-dom';
//import mainImg from 'assets/images/projects/detail/mitha-haritham-residential-apartment-trichy.jpg';
import ProjectFeatures from './highlights/projectFeatures';
import Projects from 'components/common/projects';
import Amenities from './highlights/amenities';
import Specification from './highlights/specification';
import LocationAdvantage from './highlights/locationAdvantage';
import Flat1 from './floorPlans/flat1';
import Flat2 from './floorPlans/flat2';
import Flat3 from './floorPlans/flat3';
import Flat4 from './floorPlans/flat4';
import Flat5 from './floorPlans/flat5';
import Elevation from './gallery/elevation';
import Interiors from './gallery/interiors';
import GalleryAmenities from './gallery/galleryAmenities';
import SiteProgress from './gallery/siteProgress';
import SiteProgress1 from './gallery/siteProgress1';
import SiteProgress2 from './gallery/siteProgress2';
import SiteProgress3 from './gallery/siteProgress3';
import SiteProgress4 from './gallery/siteProgress4';
import SiteProgress5 from './gallery/siteProgress5';
import SiteProgress6 from './gallery/siteProgress6';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

async function enquiryData(credentials) {
    return fetch('https://mithahomes.com/phpapi/siteapi/enquiry-api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
}

class ProjectDetail extends Component {
    constructor(props) {
        super(props)
            this.state = {
            project_name: '',
            project_type: '',
            project_location: '',
            project_highlights:[],
            project_status: '',
            project_overview: [],
            amenities: [],
            specification: [],
            location_advantage: [],
            video_link:'',
            location_map: '',
            google_map: '',
            attached_brochare: '',
            walkthrough_link: '',
            get_direction: '',
    
            plan_name:'FLOOR PLANS',
            flat_det:[],
            flat_img:[],
            flat_img_edit:[],
        
            area_market_price: '',
            area_our_price:'',
            area_flat_number:[],
            area_total_sqft:'',
            area_noofbhk:'',
    
            property_specification_left:[],
            property_specification_right:[],
    
            ps_details: [],
            ps_payment: '',
    
            gtype1: '',
            gtype2: '',
            gtype3: '',
            gtype4: '',
            gtype5: '',
            gtype6: '',
            gtype7: '',
            gtype8: '',
            gtype9: '',
            gtype10: '',
    
            gtimg1:[],
            gtimg1_edit:[],
            gtdet1:[],
        
            gtimg2:[],
            gtimg2_edit:[],
            gtdet2:[],
            
            gtimg3:[],
            gtimg3_edit:[],
            gtdet3:[],
    
            gtimg4:[],
            gtimg4_edit:[],
            gtdet4:[],
    
            gtimg5:[],
            gtimg5_edit:[],
            gtdet5:[],

            gtimg6:[],
            gtimg6_edit:[],
            gtdet6:[],

            gtimg7:[],
            gtimg7_edit:[],
            gtdet7:[],

            gtimg8:[],
            gtimg8_edit:[],
            gtdet8:[],

            gtimg9:[],
            gtimg9_edit:[],
            gtdet9:[],

            gtimg10:[],
            gtimg10_edit:[],
            gtdet10:[],

            ovimg:'',
            cname:'',
            mobile:'',
            email:'',
    
            pcid:0,
            pid:0,
            pcategory:[],
            project_cover_img:'',
            project_small_img:'',
            upstatus: '',
            loading: false, 
            ptitle:'',
            mdesc:'',
            mkeyword:'',
            vtitle: '',
            oglink:'', 
            edit:0,
        }
    }

    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }

    componentDidUpdate() {
        if(this.state.pid!==0){
        if(this.props.match.params.id !== this.state.pid) {
            this.fetchDatas(); 
        }
    }
  
    }

    handleSubmit = async e => {
        e.preventDefault();
      //  console.log("Name is : " + name + "Mobile no. is : " + mobile + "Email ID : " + emailId + "City is : " + city);  eq-project-list
    
        const response = await enquiryData({
            name:this.state.cname, mobile:this.state.mobile, email:this.state.email, city:"", pid: this.state.pid, ltype: "eform"
        });
      
        if(JSON.stringify(response.success) === '1') {
             alert("Your Details Updated. Contact Sortly");
        } else {
            console.log('Insert Failure'+response.success);
        }
    
   
      
    }
  
    async componentDidMount() {
        this.setState({
            loading: true,
        });
        this.fetchDatas(); 
    }
  
    fetchDatas = () => { 
        fetch('https://mithahomes.com/phpapi/siteapi/project-details.php?no='+this.props.match.params.id)
            .then(response => {
              response.json().then(function (data) {
                if (data.success === 1) {
                  this.setState({
                    pid: data.pid,
                    project_name: data.project_name,
                    project_type: data.project_type,
                    project_location: data.project_location,
                    project_highlights: data.project_highlights,
                    project_status: data.project_status,
                    project_overview: data.project_overview,
                    amenities: data.amenities,
                    specification: data.specification,
                    location_advantage: data.location_advantage,
                    video_link: data.video_link,
                    location_map: data.location_map,
                    google_map: data.google_map,
                    attached_brochare: data.attached_brochare,
                    walkthrough_link: data.walkthrough_link,
                    get_direction: data.get_direction,
            
                    plan_name: data.plan_name,
                    flat_det: data.flat_det,
                    flat_img: data.flat_img,
                    ovimg: data.ovimg,
                
                    area_market_price: data.area_market_price,
                    area_our_price: data.area_our_price,
                    area_flat_number: data.area_flat_number,
                    area_total_sqft: data.area_total_sqft,
                    area_noofbhk: data.area_noofbhk,
            
                    property_specification_left: data.property_specification_left,
                    property_specification_right: data.property_specification_right,
            
                    ps_details: data.ps_details,
                    ps_payment: data.ps_payment,
            
                    gtype1: data.gtype1,
                    gtype2: data.gtype2,
                    gtype3: data.gtype3,
                    gtype4: data.gtype4,
                    gtype5: data.gtype5,
                    gtype6: data.gtype6,
                    gtype7: data.gtype7,
                    gtype8: data.gtype8,
                    gtype9: data.gtype9,
                    gtype10: data.gtype10,
            
                    gtimg1:data.gtype1_img,
                    gtdet1:data.gtype1_det,
                
                    gtimg2:data.gtype2_img,
                    gtdet2:data.gtype2_det,
                    
                    gtimg3:data.gtype3_img,
                    gtdet3:data.gtype3_det,
            
                    gtimg4:data.gtype4_img,
                    gtdet4:data.gtype4_det,
            
                    gtimg5:data.gtype5_img,
                    gtdet5:data.gtype5_det,

                    gtimg6:data.gtype6_img,
                    gtdet6:data.gtype6_det,

                    gtimg7:data.gtype7_img,
                    gtdet7:data.gtype7_det,

                    gtimg8:data.gtype8_img,
                    gtdet8:data.gtype8_det,

                    gtimg9:data.gtype9_img,
                    gtdet9:data.gtype9_det,

                    gtimg10:data.gtype10_img,
                    gtdet10:data.gtype10_det,
            
                    pcid:data.pcid,
                    project_cover_img:data.project_cover_img,
                    project_small_img:data.project_small_img,
                    vtitle: data.vtitle,
                    ptitle: data.ptitle,
      mdesc: data.mdesc,
      mkeyword: data.mkeyword,
      oglink: data.oglink,
                    
                    upstatus: data.islive,
                    loading: false,
                  });
                //   document.head.innerHTML+=`<meta property="og:url" content="https://mithahomes.com/" />
                //   <meta property="og:type" content="website" />
                //   <meta property="og:title" content=`+data.ptitle+` />
                //   <meta property="og:image" itemprop="image" content=`+data.oglink+` />
                //   <meta property="og:image:url" itemprop="image" content=`+data.oglink+` />
                //   <meta property="og:description" content=`+data.mdesc+` />`;

                }
                else {
                    this.setState({
                        loading: false,
                    });
                }
              }.bind(this));
            })
            .catch(error => {
                console.log(error);
            });
  
    }

    render() {        
        const dataSource = this.state;

        return dataSource.loading===true ? '' : (
            <React.Fragment>
          
                 <Helmet>
        <title>{this.state.ptitle}</title>
        <meta name="description" content={this.state.mdesc} />
        <meta name="keywords" content={this.state.mkeyword} />
        <meta property="og:url" content="https://mithahomes.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.state.ptitle} />
        <meta property="og:image" itemprop="image" content={this.state.oglink} />
        <meta property="og:image:url" itemprop="image" content={this.state.oglink} />
        <meta property="og:description" content={this.state.mdesc} />
        <meta property="twitter:title" content={this.state.ptitle} />
        <meta property="twitter:description" content={this.state.mdesc} />
       
                </Helmet>
              
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header" id="projectHighlights">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> <Link to="/"> Projects </Link> </li>
                            <li> {dataSource.project_name} </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: PROJECT DETAIL CONTENT */}
                <section className="project-detail">
                    <div className="construction-updates">
                        <div className="left">
                            <img src={dataSource.project_cover_img} alt="" />
                        </div>
                        <div className="right">
                            <h3> 
                                <span> {dataSource.project_name} HIGHLIGHTS </span> 

                                <div className="share-wrap">
                                    <label htmlFor="mainShare" className="share-btn">
                                        <i className="fa fa-share-alt"></i>
                                    </label>
                                    <input type="checkbox" className="checkbox-share" id="mainShare" />

                                    <div className="share-icons-box" >
                                    <a className="share-icon fb" type="button" role="button" title="Share on facebook"
   href={"https://www.facebook.com/sharer/sharer.php?u=https://mithahomes.com/react/project-detail/"+this.state.pid} target="_blank" rel="noopener">
  <i className="fa fa-facebook"></i> </a>

  <a className="share-icon tw" type="button" role="button" title="Share on Instagram"
   href={"https://www.instagram.com/?url=https://mithahomes.com/react/project-detail/"+this.state.pid} target="_blank" rel="noopener">
  <i className="fa fa-instagram"></i> </a>

  <a className="share-icon wh" type="button" role="button" title="Share on Instagram"
   href={"whatsapp://send?text=https://mithahomes.com/react/project-detail/"+this.state.pid} target="_blank" rel="noopener">
  <i className="fa fa-whatsapp"></i> </a>
                                        {/* <Link to="/" className="share-icon fb"> <i className="fa fa-facebook"></i> </Link>
                                        <Link to="/" className="share-icon tw"> <i className="fa fa-twitter"></i> </Link>
                                        <Link to="/" className="share-icon wh"> <i className="fa fa-whatsapp"></i> </Link> */}
                                    </div>
                                </div>
                            </h3>

                            <ul className="style-1">
                                {dataSource.project_highlights.map((value) => (
                                    <li> {value} </li>
                                ))}  
                            </ul>

                            <div className="update-bar-holder">
                                <h4> 
                                    Construction Current Status 
                                    {this.state.project_status === "100" ? <span className="sold-out-bar"> sold out </span> : "" }
                                </h4>

                                <div className="bar-box">
                                    <div className="bar-level" style={{paddingLeft:dataSource.project_status+'%'}}> 
                                        <span> {dataSource.project_status}%  </span>
                                        <div className="level" style={{width:dataSource.project_status+'%'}}></div>
                                    </div>
                                    <div className="bar"></div>

                                    <div className="inner">
                                        <span className="from"> 0% </span>
                                        <span className="to"> 100% </span>
                                    </div>
                                </div>
                            </div>

                            <div className="available">
                                <div className="right">
                                    {/* <Link to="/" className="btn-3">  */}
                                    <a href='#enquireNow' className="btn-3">     Enquire now <img src='https://mithahomes.com/demo/images/arrow-right-orange.png' alt="" />  </a>
                                    {/* </Link> */}
                                </div>

                                <div className="right">
                                </div>
                            </div>

                            
                            <div className="btn-groups">
                                <a href={dataSource.attached_brochare} target="_blank" className="btns btn-1" rel="noreferrer"><i className="fa fa-download"></i> Broucher</a>
                                <a href={dataSource.walkthrough_link} target="_blank" className="btns btn-4" rel="noreferrer"><i className="fa fa-user-o"></i> {dataSource.vtitle}</a>
                                <a href={dataSource.get_direction} target="_blank" className="btns btn-5" rel="noreferrer"> <i className="fa fa-long-arrow-right"></i> Get Direction</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="highlights">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> {dataSource.project_name} </h2>
                            <p className="lead"> {dataSource.project_location} </p>
                            <p className="lead"> {dataSource.project_type} </p>
                        </div>
                    </div>

                    <div className="container">
                        <nav className="nav-tabs-holder">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#projectFeatures" role="tab" aria-selected="false">
                                    Project Overview
                                </button>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#amenities" role="tab" aria-selected="false">
                                    Amenities
                                </button>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#specification" role="tab" aria-selected="false">
                                    Specification
                                </button>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#locationAdvantage" role="tab" aria-selected="false">
                                    Location Advantage
                                </button>
                            </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent">
                            {/* BEGIN :: PROJECT FEATURES */}
                            <div className="tab-pane fade show active" id="projectFeatures" role="tabpanel">
                                <ProjectFeatures overviewData={dataSource} />
                            </div>
                            {/* END :: PROJECT FEATURES */}

                            {/* BEGIN :: AMENITIES */}
                            <div className="tab-pane fade" id="amenities" role="tabpanel">
                                <Amenities amenitiesData={dataSource} />
                            </div>
                            {/* END :: AMENITIES */}

                            {/* BEGIN :: SPECIFICATION */}
                            <div className="tab-pane fade" id="specification" role="tabpanel">
                                <Specification specificationData={dataSource} />
                            </div>
                            {/* END :: SPECIFICATION */}

                            {/* BEGIN :: LOCATION ADVANTAGE */}
                            <div className="tab-pane fade" id="locationAdvantage" role="tabpanel">
                                <LocationAdvantage localData={dataSource} />
                            </div>
                            {/* END :: LOCATION ADVANTAGE */}
                        </div>
                    </div>
                </section>
                {dataSource.flat_det[0]!==""?
                <section className="floor-plans">
                
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> {dataSource.plan_name} </h2>
                        </div>
                    </div>

                    <div className="container">
                        <nav className="nav-tabs-holder">
                            <div className="nav nav-tabs" id="nav-tab-floor" role="tablist">

                            {dataSource.flat_det.map((value,index) => (
                            index===0? <button className="nav-link active" data-bs-toggle="tab" data-bs-target={"#flat"+(index+1)} role="tab" aria-selected="true">
                                {value}
                            </button>
                            :<button className="nav-link" data-bs-toggle="tab" data-bs-target={"#flat"+(index+1)} role="tab">
                                    {value}
                                </button>
                           
                            ))}  
                             
                            </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent-floor">
                            {/* BEGIN :: FLAT 1 */}
                            <div className="tab-pane show active" id="flat1" role="tabpanel">
                                <Flat1 source={dataSource.flat_img} />
                            </div>
                            {/* END :: FLAT 1 */}
                            
                            {/* BEGIN :: FLAT 2 */}
                            <div className="tab-pane fade" id="flat2" role="tabpanel">
                                <Flat2 source={dataSource.flat_img} />
                            </div>
                            {/* END :: FLAT 2 */}
                            
                            {/* BEGIN :: FLAT 3 */}
                            <div className="tab-pane" id="flat3" role="tabpanel">
                                <Flat3 source={dataSource.flat_img} />
                            </div>
                            {/* END :: FLAT 3 */}
                            
                            {/* BEGIN :: FLAT 4 */}
                            <div className="tab-pane" id="flat4" role="tabpanel">
                                <Flat4 source={dataSource.flat_img} />
                            </div>
                            {/* END :: FLAT 4 */}
                            
                            {/* BEGIN :: FLAT 5 */}
                            <div className="tab-pane" id="flat5" role="tabpanel">
                                <Flat5 source={dataSource.flat_img} />
                            </div>
                            {/* END :: FLAT 5 */}
                        </div>
                    </div>

                    {/*
                        <div className="container container-enquire-now-btn">
                            <Link to="#enquireNow" className="btns btn-3"> 
                                Enquire now <img src='https://mithahomes.com/demo/images/arrow-right-orange.png' alt="" /> 
                            </Link>
                        </div>
                    */}
                </section>:""}
                
                {dataSource.gtype1!==""?
                <section className="gallery">

               
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> GALLERY </h2>
                        </div>
                    </div>

                    <div className="container">
                        <nav className="nav-tabs-holder">
                            <div className="nav nav-tabs" id="nav-tab-gallery" role="tablist">
                                {dataSource.gtype1!==""?<button className="nav-link active" data-bs-toggle="tab" data-bs-target="#elevation" role="tab" aria-selected="true">
                                {dataSource.gtype1}
                                </button>:""}
                                
                                {dataSource.gtype2!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#interiors" role="tab" aria-selected="false">
                                {dataSource.gtype2}
                                </button>:""}
                                {dataSource.gtype3!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#galleryAmenities" role="tab" aria-selected="false">
                                {dataSource.gtype3}
                                </button>:""}
                                {dataSource.gtype4!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress" role="tab" aria-selected="false">
                                {dataSource.gtype4}
                                </button>:""}
                                {dataSource.gtype5!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress1" role="tab" aria-selected="false">
                                {dataSource.gtype5}
                                </button>:""}
                                {dataSource.gtype6!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress2" role="tab" aria-selected="false">
                                {dataSource.gtype6}
                                </button>:""}
                                {dataSource.gtype7!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress3" role="tab" aria-selected="false">
                                {dataSource.gtype7}
                                </button>:""}
                                {dataSource.gtype8!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress4" role="tab" aria-selected="false">
                                {dataSource.gtype8}
                                </button>:""}
                                {dataSource.gtype9!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress5" role="tab" aria-selected="false">
                                {dataSource.gtype9}
                                </button>:""}
                                {dataSource.gtype10!==""?<button className="nav-link" data-bs-toggle="tab" data-bs-target="#siteProgress6" role="tab" aria-selected="false">
                                {dataSource.gtype10}
                                </button>:""}
                            </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent-gallery">
                            {/* BEGIN :: ELEVATION */}
                            {dataSource.gtype1!==""?<div className="tab-pane fade show active" id="elevation" role="tabpanel">
                                <Elevation source={dataSource} />
                            </div>:""}
                            {/* END :: ELEVATION */}
                            
                            {/* BEGIN :: INTERIORS */}
                            {dataSource.gtype2!==""?<div className="tab-pane fade show" id="interiors" role="tabpanel">
                                <Interiors source={dataSource} />
                            </div>:""}
                            {/* END :: INTERIORS */}
                            
                            {/* BEGIN :: AMENITIES */}
                            {dataSource.gtype3!==""?<div className="tab-pane fade show" id="galleryAmenities" role="tabpanel">
                                <GalleryAmenities source={dataSource} />
                            </div>:""}
                            {/* END :: AMENITIES */}
                            
                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype4!==""?<div className="tab-pane fade show" id="siteProgress" role="tabpanel">
                                <SiteProgress source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                             {dataSource.gtype5!==""?<div className="tab-pane fade show" id="siteProgress1" role="tabpanel">
                                <SiteProgress1 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype6!==""?<div className="tab-pane fade show" id="siteProgress2" role="tabpanel">
                                <SiteProgress2 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype7!==""?<div className="tab-pane fade show" id="siteProgress3" role="tabpanel">
                                <SiteProgress3 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype8!==""?<div className="tab-pane fade show" id="siteProgress4" role="tabpanel">
                                <SiteProgress4 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype9!==""?<div className="tab-pane fade show" id="siteProgress5" role="tabpanel">
                                <SiteProgress5 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                            {/* BEGIN :: SITE PROGRESS */}
                            {dataSource.gtype10!==""?<div className="tab-pane fade show" id="siteProgress6" role="tabpanel">
                                <SiteProgress6 source={dataSource} />
                            </div>:""}
                            {/* END :: SITE PROGRESS */}

                        </div>
                    </div>
                </section>:""}

                <section className="configuration">
                    {dataSource.area_our_price!==""?
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> AREA STATEMENT </h2>
                            {/* <div className="configuration-box">
                                <span> Market Price : <b> <i className="fa fa-rupee"></i> {dataSource.area_market_price } </b> </span>
                                <span> Our Price : <b> <i className="fa fa-rupee"></i> {dataSource.area_our_price } </b> </span>
                            </div>
                            <p className="lead"> *Price mentioned is the Basic cost which is Exclusive of other charges. </p> */}
                        </div>
                    </div>:""}

                    <div className="container">
                    {dataSource.area_flat_number[0]!==""?
                        <div className="phase-box">
                            <div className="small-table-wrapper">
                                <table className="table table-configuration table-striped">
                                    <thead>
                                        <tr>
                                            <td> Flat Number </td>
                                            <td> Total SQ.FT </td>
                                            <td> No.of BHK </td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {dataSource.area_flat_number.map((value,index) => (
                                            <tr>
                                                <td data-xs="Flat no."> {value} </td>
                                                <td data-xs="Total SQ.FT"> {dataSource.area_total_sqft[index]} </td>
                                                <td data-xs="No.of BHK"> {dataSource.area_noofbhk[index]} </td>
                                            </tr>   
                                        ))}    
                                    </tbody>
                                </table>
                            </div>
                        </div> :""}

                        {dataSource.property_specification_left[0]!==""?
                        
                        <div className="phase-box" id="propertySpecifiations">
                            <div className="title-1 text-center">
                                <h2> SPECIFICATION </h2>
                            </div>

                            <div className="small-table-wrapper">
                                <table className="table table-specification table-striped">
                                    <tbody> 
                                    {dataSource.property_specification_left.map((value,index) => (

                                        <tr>
                                            <th> {value} </th>
                                            <td> <div dangerouslySetInnerHTML={{ __html: dataSource.property_specification_right[index] }} /> </td>
                                        </tr> 
                                    ))}  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :""}

                {dataSource.ps_details[0]!==""?
                        <div className="phase-box">
                            <div className="title-1 text-center">
                                <h2> PAYMENT SCHEDULE </h2>
                            </div>
                            <div className="small-table-wrapper">
                                <table className="table table-specification table-striped">
                                    <thead>
                                        <tr>
                                            <td> Details </td>
                                            <td> Payment </td>
                                        </tr>
                                    </thead>

                                    <tbody>                    
                                    {dataSource.ps_details.map((value,index) => (
                       <tr>
                       <td data-xs="Details"> <p> {value} </p> </td>
                       <td data-xs="Payment"> <p> {dataSource.ps_payment[index]} </p> </td>
                   </tr>  
                           
                            ))}          
                                        
                                        <tr>
                                            <td colSpan="2"> 
                                                <p>
                                                    # For Loan Customers - Registration will be done when the balance amount is due only from the Home Loan.
                                                </p> 
                                                <p> # For non-Loan Customers - Registration will be done only after receiving full payment. </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :"" }
                    </div>
                </section>

                <section className="your-community">
                    <div className="grid-community-location">
                        <div className="left">
                            <div className="title-1 text-center">
                                <h2> Google Location  </h2>
                            </div>
                            <div className="iframe-box">
                                <iframe src={dataSource.google_map} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div className="right">
                            <div className="title-1 text-center">
                                <h2> Location Map </h2>
                            </div>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                                group="group1"
                            >
                                <a href={dataSource.location_map}>
                                    <img src={dataSource.location_map} alt="" />
                                </a>
                            </LightGallery>
                        </div>
                    </div>
                </section>

                <section className="enquire-now" id="enquireNow">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> Enqurie now </h2>
                            <p> HI! I'M INTERESTED IN THIS PROJECT! </p>
                        </div>
                    </div>

                    <div className="container">
                        <form onSubmit={this.handleSubmit} action="" method="post">
                            <div className="grid-enquire-now">
                                <div className="field-box">
                                    <input type="text" className="field" value={this.state.cname} onChange={this.handleInputChange.bind(this)} required name="cname" placeholder="Name" />
                                </div>
                                
                                <div className="field-box">
                                    <input type="text" className="field" value={this.state.mobile} onChange={this.handleInputChange.bind(this)} required name="mobile" placeholder="Mobile Number" />
                                </div>
                                
                                <div className="field-box">
                                    <input type="text" className="field" value={this.state.email} onChange={this.handleInputChange.bind(this)} required name="email" placeholder="Email" />
                                </div>

                                <div className="field-box">
                                    <button>
                                        Submit
                                        <img src='https://mithahomes.com/demo/images/arrow-right-orange.png' alt="" /> 
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>

                <section className="project-near-by projects">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> OUR OTHER PROJECTS </h2>
                        </div>
                    </div>

                    <div className="container">
                        <div className="grid-project-box">
                            <Projects projectId={this.props.match.params.id} />
                        </div>
                    </div>
                </section>
                {/* END :: PROJECT DETAIL CONTENT */}
                
                <Footer />
                
            </React.Fragment>
        );
    }
}

export default ProjectDetail;