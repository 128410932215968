import React, { Component } from 'react';

class About extends Component {
    render() {        
        return (
            <React.Fragment>

            <section className="about">
                <div className="container">
                    <div className="title-1 text-center">
                        <h2> ABOUT MITHA HOMES </h2>
                    </div>
                </div>
        
                <div className="container">
                    <div className="grid-about-icon-box">
                        {/* BEGIN :: ABOUT ICON BOX */}
                        <div className="about-icon-box">
                            <i className="fa fa-home"></i>
                            <p> DESIGNING AND INNOVATING GREAT HOMES </p>
                        </div>
                        {/* END :: ABOUT ICON BOX */}
                        
                        {/* BEGIN :: ABOUT ICON BOX */}
                        <div className="about-icon-box">
                            <i className="fa fa-group"></i>
                            <p> BUILDING ASPIRATIONS WITH OUR HOMES </p>
                        </div>
                        {/* END :: ABOUT ICON BOX */}
                        
                        {/* BEGIN :: ABOUT ICON BOX */}
                        <div className="about-icon-box">
                            <i className="fa fa-clock-o"></i>
                            <p> DELIVERING ON TIME, EVERY TIME </p>
                        </div>
                        {/* END :: ABOUT ICON BOX */}
                    </div>
        
                    <div className="grid-about-content-box">
                        <div className="about-content-box">
                            <p className="lead"> 
                                Mitha Construction and Development is a registered construction firm in Tiruchirappalli, headed by S.Mohan, a qualified civil engineer from Seshasayee Institute of Technology (SIT). He finished his course in 1985 and practised under very experienced and leading engineers during the construction of big hotels and residential bungalows. He then ventured into his family business of printing and packaging industry and turned out to be a successful businessman. But his passion for construction brought him back and he has started his journey as a flat promoter. The principle objective of the company is to provide quality construction at an affordable cost. The company has a strong team of dedicated engineers and architects to execute projects as per the specifications and requirements of the clients.
                            </p>
                            <p className="lead"> 
                                We also feel elated to say that we have a strong reputation among 
                                our clients and we assure a <strong> best value for your investment </strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        );
    }
}

export default About;