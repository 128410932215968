import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { getTestimonials } from "../../_services/fakeTestimonials";

class Testimonials extends Component {
    state = {
        testimonials : []
    }

    // async componentDidMount() {
    //     const testimonials = getTestimonials();
    //     this.setState({ testimonials });
    // }
    async componentDidMount() {
        fetch("https://mithahomes.com/phpapi/siteapi/testimonialsvideo.php")
        .then(response => {
            response.json().then(function(data) {
            if (data.success === 1) {
                this.setState({
                    testimonials: data.data,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
            }.bind(this));
        })
        .catch(error => {
            console.log(error);
        });
    }
    render() {
        const { testimonials } = this.state;

        return (
            <React.Fragment>
            {/* BEGIN :: TESTIMONIAL BOX */}
            {
                testimonials.map((testimonial) => (
                    <Link key={testimonial.acc} to={"/testimonial-detail/"+testimonial.acc} title={testimonial.cname} className="testimonial-box">
                        <div className="img-wrap">
                            <img src={testimonial.cimg} alt="" />
                            <div className="abs-overlay">
                                <span> <i className="fa fa-youtube-play"></i> </span>
                            </div>
                        </div>
                        <div className="content">
                            <h3> {testimonial.cname} </h3>
                            <span> {testimonial.csub} </span>
                        </div>
                    </Link>
                ))
            }
            {/* END :: TESTIMONIAL BOX */}
            </React.Fragment>
        );
    }
}
export default Testimonials;