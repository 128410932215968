import React, { Component } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';

class SiteProgress1 extends Component {
    render() {        
        let source = this.props.source;           
        // const onInit = () => {
        //     console.log('lightGallery has been initialized');
        // };
                    
        return (
            <React.Fragment>
                <div className="grid-gallery">
                    <LightGallery speed={500} plugins={[lgZoom]}>
                        {/* BEGIN :: GALLERY BOX */}
                        {source.gtimg7.map((value,index) => (
                            <a href={value} className="gallery-box">
                                <div className="wrap"> <img src={value} alt={source.gtdet7[index]} /> </div>
                                <h3> {source.gtdet7[index]}  </h3>
                            </a> 
                        ))}  
                        {/* END :: GALLERY BOX */}
                    </LightGallery>
                </div>
            </React.Fragment>
        );
    }
}

export default SiteProgress1;