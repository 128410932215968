
const testimonials = [
  {
    _id: "5b21ca3eeb7f6fbccd471815",
    image: "https://mithahomes.com/demo/images/testimonials/testimonial-1.jpg",
    name : "Mr.Rajesh Palanivel",
    title : "Mitha homes Utopia"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471816",
    image: "https://mithahomes.com/demo/images/testimonials/testimonial-2.jpg",
    name : "Mr.Rajesh Palanivel",
    title : "Mitha homes Utopia"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471817",
    image: "https://mithahomes.com/demo/images/testimonials/testimonial-3.jpg",
    name : "Mr.Rajesh Palanivel",
    title : "Mitha homes Utopia"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471818",
    image: "https://mithahomes.com/demo/images/testimonials/testimonial-4.jpg",
    name : "Mr.Rajesh Palanivel",
    title : "Mitha homes Utopia"
  }
];

const testimonialsText = [
  {
    _id: "4txtb7f6fbccd471815",
    image: "https://mithahomes.com/images/N-Balasubramanian.jpg",
    name : "N.Balasubramanian",
    designation : "Retired Officer"
  },
  {
    _id: "4txtb7f6fbccd471816",
    image: "https://mithahomes.com/images/chokkanathan.jpg",
    name : " CA.B.S.Chokkanathan / CA.D.SARULATHA",
    designation : " Chartered Accountant "
  },
  {
    _id: "4txtb7f6fbccd471817",
    image: "https://mithahomes.com/images/shanthakumari.jpg",
    name : " A.S.Amurudheen",
    designation : "Joint General Manager"
  },
  {
    _id: "4txtb7f6fbccd471818",
    image: "https://mithahomes.com/images/a.s.amurudheen.jpg",
    name : "Beach clean up activity",
    designation : "Govt. School Teacher"
  }
];

export function getTestimonials() {
  return testimonials;
}

export function getTestimonialsText() {
  return testimonialsText;
}

