    import React, { Component } from 'react';
    import { Link } from 'react-router-dom';
    import {Helmet} from "react-helmet-async";
    import Header from 'components/common/header';
    import Footer from 'components/common/footer';
    import LightGallery from 'lightgallery/react';
    import 'lightgallery/css/lightgallery.css';
    import 'lightgallery/css/lg-zoom.css';
    import 'lightgallery/css/lg-thumbnail.css';
    import lgZoom from 'lightgallery/plugins/zoom';

   // import img1 from 'assets/images/Mitha-Haritham.jpg';

    class NewsMedia extends Component {
        constructor(props) {
            super(props)
            this.state = {
                bname: '',
                pdate: '',
                bdet: '',
                bimg: '',
                loading: false,
                mdesc:'',
                mkeyword:'',
                images : []
            }
        }

        async componentDidMount() {
            this.setState({
                loading: true,
            });
            this.fetchDatas();  
        }

        fetchDatas = () => { 
            const blogId = this.props.match.params.id;
            fetch('https://mithahomes.com/phpapi/siteapi/blog-details.php?no='+blogId)
                .then(response => {
                    response.json().then(function(data) {
                    if(data.success === 1) {
                        this.setState({
                            bname: data.bname,
                            pdate: data.pdate,
                            bdet: data.bdet,
                            bimg: data.bimg,
                            images: data.bm_imgs,
                            mdesc: data.mdesc,
                            mkeyword: data.mkeyword,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                    }
                }.bind(this));
            })
            .catch(error => {
                console.log(error);
            });
        }

        getImgClasses() {
            let images = this.state.images;
            let totalImages = images.length;

            return totalImages > 5 ? 'imgs-grid imgs-grid-5' : 'imgs-grid imgs-grid-'+totalImages;
        }

        render() {      
            const onInit = () => {
                console.log('lightGallery has been initialized');
            };
            const imageCollections = this.state.images;
           // let totalImages = imageCollections.length;

            return (
                <React.Fragment>
                    <Helmet>
        <title>{this.state.bname}</title>
        <meta name="description" content={this.state.mdesc} />
        <meta name="keywords" content={this.state.mkeyword} />
        <meta property="og:title" content={this.state.bname} />
        <meta property="og:image" itemprop="image" content={this.state.bimg} />
        <meta property="og:image:url" itemprop="image" content={this.state.bimg} />
        <meta property="og:description" content={this.state.mdesc} />
        <meta property="twitter:title" content={this.state.bname} />
        <meta property="twitter:description" content={this.state.mdesc} />
        <meta property="og:url" content="https://mithahomes.com/" />
        <meta property="og:type" content="website" />
                </Helmet>
                    <Header />

                    {/* BEGIN :: PAGE HEADER */}
                    <section className="page-header">
                        <div className="container">
                            <ul>
                                <li> <Link to="/"> Home </Link> </li>
                                <li> <Link to="/"> News & Media </Link> </li>
                                <li> {this.state.bname} </li>
                            </ul>
                        </div>
                    </section>
                    {/* END :: PAGE HEADER */}

                    {/* BEGIN :: NEWS MEDIA CONTENT */}
                    <section className="testimonials type-2">
                        <div className="container">
                            <div className="title-1 text-center">
                                <p> <i className="fa fa-calendar-o"></i> Posted On : {this.state.pdate}  </p>
                                <h2> {this.state.bname}  </h2>
                                {/* <p>
                                {this.state.bdet} 
                                </p> */}
                            </div>
                        </div>

                        <div className="container">
                            <div className="grid-testimonials-detail grid-news-detail">
                            {this.state.loading===false ?
                                <div className={this.getImgClasses()}>
                                    <LightGallery
                                        onInit={onInit}
                                        speed={500}
                                        plugins={[lgZoom]}
                                        group="group1"
                                    >
                                        {imageCollections.map((item, index) =>
                                                <a href={item} className="imgs-grid-image"> 
                                                    <img src={item} alt="Mitha Homes" />
                                                    {
                                                    (imageCollections.length > 5 && index === 4) ? 
                                                        <div class="view-all"><span class="view-all-cover"></span>
                                                            <span class="view-all-text">+{Math.abs(imageCollections.length - 5)}</span>
                                                        </div> : null 
                                                    }
                                                </a>
                                            )
                                        }
                                    </LightGallery>
                                </div>
                                :<div></div>}
                                <div className="content">
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.bdet }} /> 
                                    </div>                           

                                    <div className="share-box-detail">
                                        <span> <i className="fa fa-share-alt"></i> Share : </span>
                                        <Link to="/"> <i className="fa fa-facebook"></i> </Link>
                                        <Link to="/"> <i className="fa fa-twitter"></i> </Link>
                                        <Link to="/"> <i className="fa fa-youtube"></i> </Link>
                                        <Link to="/"> <i className="fa fa-instagram"></i> </Link>
                                    </div>
                                </div>

                                {/* <div className="archieve-box">
                                    <select name="" id="">
                                        <option value=""> Filter by Archieves </option>
                                    </select>

                                    <select name="" id="">
                                        <option value=""> Filter by Titles </option>
                                    </select>
                                    
                                    <button className="btn-1"> Submit </button>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    {/* END :: NEWS MEDIA CONTENT */}
                    
                    <Footer />
                </React.Fragment>
            );
        }
    }
    
    export default NewsMedia;