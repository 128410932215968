import React, { Component } from 'react';
import Header from 'components/common/header';
import Projects from 'components/common/projects';
import Footer from 'components/common/footer';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

class NewsMediaDetail extends Component {
    render() {        
        return (
            <React.Fragment>
                 <Helmet>
        <title>Mitha Homes</title>
    <meta name="description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> Projects </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: NEWS MEDIA CONTENT */}
                <section className="projects">
                <div className="container">
                        <div className="grid-project-box">
                            <Projects />
                        </div>
                    </div>
                </section>
                {/* END :: NEWS MEDIA CONTENT */}
                
                <Footer />
            </React.Fragment>
        );
    }
}

export default NewsMediaDetail;