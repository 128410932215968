import React,  { useState, useEffect } from 'react';
//import {Fetchurl} from 'components/context';

async function enquiryData(credentials) {
    return fetch('https://mithahomes.com/phpapi/siteapi/enquiry-api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
}

const EnquireNow = () => {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [emailId, setEmailId] = useState("");
    const [city, setCity] = useState("");
    const [project, setProject] = useState("");
    const [plist, setPlist] = useState([]);

    useEffect(() => {
        //Runs only on the first render
        fetch('https://mithahomes.com/phpapi/siteapi/eq-project-list.php')
        .then(response => {
          response.json().then(function (data) {
            if (data.success === 1) {
                setPlist(data.data);
            }
            
          });
        })
        .catch(error => {
          console.log(error);
        });
  
      }, []);

    const handleSubmit = async e => {
        e.preventDefault();
      //  console.log("Name is : " + name + "Mobile no. is : " + mobile + "Email ID : " + emailId + "City is : " + city);  eq-project-list
        const response = await enquiryData({
            name:name, mobile:mobile, email:emailId, city:city, pid: project, ltype: "eform"
        });
          
        if(JSON.stringify(response.success) === '1') {
            console.log('Insert Success');
            alert("Your Details Updated. Contact Sortly");
        } else {
            console.log('Insert Failure'+response.success);
        }
    
        // document.getElementById("enquireNowModal").classList.remove("show", "d-block");
        // document.querySelectorAll(".modal-backdrop")
        //     .forEach(el => el.classList.remove("modal-backdrop"));
      
    }

    return (
        <React.Fragment>
            {/* BEGIN :: AUTHENTICATION MODAL */}
            
            <div className="modal fade modal-1" data-bs-backdrop="static" id="enquireNowModal" tabIndex="-1" aria-labelledby="authenticationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {/* BEGIN :: BODY */}
                        <div className="modal-body">
                            {/* BEGIN :: AUTHENTICATION MODAL GRID */}
                            <div className="authentication-modal-grid">            
                                <form className="authentication-form" id="authenticationForm" onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    
                                    {/* BEGIN :: INNER :: SIGIN FORM */}
                                    <div className="inner-modal modal-signin-form active">
                                        <h2 className="h3"> <i className="fa fa-envelope-o"></i> Enquire now </h2>
                                        <p>We are committed to providing excellent service for our customers.</p> <br />
                        
                                        {/* BEGIN :: authentication INPUT BOX */}
                                        <div className="authentication-input-box">
                                            <label htmlFor="username"> Select Project <span> * </span> </label>
                                            <select className="form-control modal-form-control" name="username" value={project} onChange={(e) => setProject(e.target.value)}>
                                            <option value=""> Select Project </option>
                                            {plist.map((item)=>
                                          <option key={item.acc} value={item.acc}> {item.project_name} </option>

                                          )}
                                              
                                            </select>
                                        </div>
                                        {/* END :: authentication INPUT BOX */}

                                        {/* BEGIN :: authentication INPUT BOX */}
                                        <div className="authentication-input-box">
                                            <label htmlFor="username"> Name <span> * </span> </label>
                                            <input className="form-control modal-form-control" data-error="Name" id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" />
                                            <span className="error-box"></span>
                                        </div>
                                        {/* END :: authentication INPUT BOX */}
                                        
                                        {/* BEGIN :: authentication INPUT BOX */}
                                        <div className="authentication-input-box">
                                            <label htmlFor="password"> Mobile <span> * </span> </label>
                                            <input className="form-control modal-form-control" data-error="Mobile" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" name="mobile" />
                                            <span className="error-box"></span>
                                        </div>
                                        {/* END :: authentication INPUT BOX */}
                                        
                                        {/* BEGIN :: authentication INPUT BOX */}
                                        <div className="authentication-input-box">
                                            <label htmlFor="password"> Email ID <span> * </span> </label>
                                            <input className="form-control modal-form-control" data-error="Email ID" id="emailId" value={emailId} onChange={(e) => setEmailId(e.target.value)} type="text" name="emailId" />
                                            <span className="error-box"></span>
                                        </div>
                                        {/* END :: authentication INPUT BOX */}
                                        
                                        {/* BEGIN :: authentication INPUT BOX */}
                                        <div className="authentication-input-box">
                                            <label htmlFor="password"> City <span> * </span> </label>
                                            <input className="form-control modal-form-control" data-error="city" id="city" value={city} onChange={(e) => setCity(e.target.value)} type="text" name="city" />
                                            <span className="error-box"></span>
                                        </div>
                                        {/* END :: authentication INPUT BOX */}

                                        <br />
                                        <button className="btn-1" id="btnSigninModal" data-bs-dismiss="modal" aria-label="Close"> Submit </button>
                                    </div>
                                    {/* END :: INNER :: SIGIN FORM */}                        
                                </form>
                            </div>
                            {/* END :: AUTHENTICATION MODAL GRID */}
                        </div>
                        {/* END :: BODY */}
                    </div>
                </div>
            </div>
            {/* END :: AUTHENTICATION MODAL */}
        </React.Fragment>
    )
}


export default EnquireNow;