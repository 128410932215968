import React, { Component } from 'react';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet-async";

class About extends Component {
    render() {        
        return (
            <React.Fragment>
                <Helmet>
        <title>Mitha Homes</title>
    <meta name="description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
    <meta name="keywords" content="Mitha Homes, independent house promoters and developers in Trichy" />
    <meta property="og:title" content="Mitha Construction & Development, Trichy" />
	<meta property="og:image" content="http://mithahomes.com/demo/images/vision-mission.png" />
	<meta property="og:description" content="Mitha Homes has established itself as one of the leading apartments, flats, independent house promoters and developers in Trichy." />
	<meta property="og:url" content="www.mithahomes.com" />
                </Helmet>
                <Header />

                {/* BEGIN :: PAGE HEADER */}
                <section className="page-header">
                    <div className="container">
                        <ul>
                            <li> <Link to="/"> Home </Link> </li>
                            <li> About </li>
                        </ul>
                    </div>
                </section>
                {/* END :: PAGE HEADER */}

                {/* BEGIN :: ABOUT CONTENT */}
                <section className="about">
                    <div className="container">
                        <div className="title-1 text-center">
                            <h2> ABOUT MITHA HOMES </h2>
                        </div>
                    </div>
            
                    <div className="container">
                        <div className="grid-about-icon-box">
                            {/* BEGIN :: ABOUT ICON BOX */}
                            <div className="about-icon-box">
                                <i className="fa fa-home"></i>
                                <p> DESIGNING AND INNOVATING GREAT HOMES </p>
                            </div>
                            {/* END :: ABOUT ICON BOX */}
                            
                            {/* BEGIN :: ABOUT ICON BOX */}
                            <div className="about-icon-box">
                                <i className="fa fa-group"></i>
                                <p> BUILDING ASPIRATIONS WITH OUR HOMES </p>
                            </div>
                            {/* END :: ABOUT ICON BOX */}
                            
                            {/* BEGIN :: ABOUT ICON BOX */}
                            <div className="about-icon-box">
                                <i className="fa fa-clock-o"></i>
                                <p> DELIVERING ON TIME, EVERY TIME </p>
                            </div>
                            {/* END :: ABOUT ICON BOX */}
                        </div>
            
                        <div className="grid-about-content-box">
                            <div className="about-content-box">
                                <img src="https://mithahomes.com/images/mohan-mithahomes.jpg" alt="" />
                                <p className="lead"> 
                                    Mitha Construction and Development is a registered construction firm in Tiruchirappalli, headed by S.Mohan, a qualified civil engineer from Seshasayee Institute of Technology (SIT). He has completed his course in 1985 and practised under very experienced and leading engineers during the construction of big hotels and residential bungalows. He then ventured into his family business of Printing and packaging industry and turned out to be a successful businessman. But his passion for construction Brought him back to this field and he started his journey as a flat promoter. The principle objective of this company is to provide quality construction at an affordable cost. The company has a strong team of dedicated engineers and architects to execute projects as per the specifications and requirements of the clients.
                                </p>
                                <p className="lead"> 
                                    We also feel elated to say that we have a strong reputation among 
                                    our clients and we assure a <strong> the best value for your investment </strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END :: ABOUT CONTENT */}
                
                <Footer />
            </React.Fragment>
        );
    }
}

export default About;